import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default ({data}) => (
  <Layout>
    <SEO title="Další významné ekologické faktory" />
    <h1>Další významné ekologické faktory</h1>
    <p><strong>Ekologický faktor</strong> je jakýkoliv činitel, který nějak ovlivňuje organismy. Působí jako podmínka prostředí, či jako zdroj. V&nbsp;předchozích kapitolách byla řeč o pH prostředí a živinách (především pak o množství dusíku), zde budou popsány na několika málo příkladech některé z&nbsp;dalších ekologických faktorů.</p>
    <hr />
    <h2>Vápník</h2>
    <p>Rozlišuje druhy kalcifilní a kalcifobní, přičemž kalcifobní rostliny jsou v&nbsp;dnešní době na ústupu (ze zemědělské a stavební činnosti se uvolňuje velké množství vápenného prachu). Dokladem, jak změna obsahu vápníku může silně ovlivnit ekosystém, je příklad sypání štěrku v&nbsp;Krkonoších.</p>
    <p>V&nbsp;Krkonoších byly během 70. let vysypávány cesty bílou mramorovou drtí (tento metamorfovaný vápenec se těžil nedaleko). V&nbsp;extrémně kyselých podmínkách Krkonoš se použitý mramor rychle rozpouštěl a začalo docházet k&nbsp;neutralizaci okolí, ale i rašelinišť.</p>
    <p>Do okolí cest a rašelinišť stékala vápnitá voda a následně docházelo k&nbsp;mineralizaci a odumírání acidofytů, naopak se zde začaly objevovat kalcifyty, které na daných stanovištích nemají co dělat. Brigádníci pak nakládají mramorový štěrk na multikáry a odvážejí ho pryč, aby bylo zamezeno dalšímu rozvrácení chemismu stanovišť. V&nbsp;důsledku toho se začala používat na štěrk žulová drť, vápník postupně vymizel a pH prostředí zvolna klesalo.</p>
    <hr />
    <h2>Sůl</h2>
    <p>Uvažuje se především přítomnost soli kuchyňské (NaCl), ale i jiných, například KCl. Rostliny vyžadující zvýšenou míru soli se nazývají halofyty, patří mezi ně například hvězdice slanistá a jitrocel přímořský.</p>
    <p>Slaniska, kde se halofytní vegetace přirozeně vyskytuje, byla na území Česka za komunismu zničena (odsolování, změna vodního režimu), zbyla pouze louka u Nesytu. U cest však dochází naopak splachem k&nbsp;zasolování okolí, zde se pak často vyskytne zblochanec oddálený.</p>
    <hr />
    <h2>Oheň</h2>
    <p>Dříve šlo v&nbsp;naší krajině o jednoznačně významný ekologický faktor, dnes však prakticky nemá žádný krajinný efekt (vše je včas uhašeno). Přitom některé ekosystémy, především v&nbsp;zahraničí, oheň nejen tolerují, ale i vyžadují.</p>
    <p>Požáry udržují určité stádium sukcese, mluvíme o tzv. <strong>ohňovém klimaxu</strong>. V&nbsp;něm je drženo zhruba 80 % savan na světě, bez požárů by zarostly lesem. Tyto savany vznikly vypálením opadavého lesa a označujeme je pojmem druhotné savany (přirozené savany označujeme termínem klimaxová savana).</p>
    <p>Kupříkladu savany v&nbsp;Africe jsou dodnes savany systematicky vypalovány, umožňuje to především snadnější a přehlednější lov, protože je takto prostředí zbaveno příliš vysoké trávy. Podobně to fungovalo i v&nbsp;amerických prériích, které často vzpláli od bouřky a původní obyvatelstvo ohni pomáhalo.</p>
    <p>V&nbsp;USA však došlo k&nbsp;zornění prérií, tím bylo zabráněno požárům. Zbylé plochy teď zarůstají lesem, na místech, kde je vůle udržet prérii bylo přistoupeno k&nbsp;umělému vypalování. V&nbsp;Českých zemích došlo k&nbsp;zákazu vypalování lesů za Karla IV., a to především kvůli nedostatku dřeva (plavení dříví po Vltavě).</p>
    <hr />
    <h2>Pyrofyty a pyrotolerantní rostliny</h2>
    <p><strong>Sekvojovec obrovský</strong> je typickým <strong>pyrofytem</strong>. Pro své rozmnožení potřebuje oheň, v&nbsp;jeho žáru se totiž otevřou spadlé šišky sekvojovce. Samotný strom ohni odolá, má 40 cm tlustou borku, která je pružná, plná vzduchu a funguje tak jako izolátor. Oheň zlikviduje podrost a malý semenáček má proto konkurenční výhodu.</p>
    <p><strong>Českou pyrotolerantní dřevinou</strong> je borovice lesní, konkrétně její ekotyp nazývaný <strong>heraltická borovice</strong>. Ve srážkovém stínu ve Velkých Heralticích dříve patrně více hořelo, k&nbsp;čemuž se uzpůsobila zde rostoucí borovice. Má velkou borku a pro vysoký, štíhlý kmen s&nbsp;malou korunou je ceněná i lesáky – jsou zde tak založeny genové základny. Bez ohně je zde však silná konkurence a heraltická borovice tolik neprospívá.</p>
    <p>Do jisté míry je pyrotolerantní i obyčejná borovice lesní, pouze však při malém požáru. Oheň totiž nesmí vystoupat do koruny, pak již oheň nepřežije.</p>
    <p>Pyrotolerantní je i plevel třtina křovistní, která obsazuje travnaté plochy i prosvětlené lesy. Její narůstající výběžky vytlačí ostatní druhy, rovněž se lze o ní pořezat (ostré hrany) a dobytek ji tak nežere. Při použití ohně akorát zlikvidujeme konkurenci třtině, ona sama přežije.</p>
    <p>Nechtěnými pyrofyty jsou i nitrofilní druhy, pokud spálíme například kupu sena, uvolní se zde množství živin – tím dáváme prostor právě nitrofilním ruderálním druhům, které zde vytlačí konkurenční rostliny.</p>
    <p>Posledním velkým ohněm u nás byl požár Bzenecké doubravy – zde chytla borová plantáž s&nbsp;nakumulovaným opadem borového jehličí s&nbsp;pryskyřicí. Lesáci pak plošně vykáceli celé zasažené území, nenechali zde stát žádné stromy. Provedli zde <em>celoplošnou přípravu půdy (odhrnutí vrchní vrstvy půdy buldozerem, necitlivé)</em> a strojově nasázeli nové sazenice přímo do písčité půdy. Sazeničky se však moc dobře neuchytily, chybělo zde vhodné mikroklima atd.</p>

    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Další významné ekologické faktory</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/nedostatek-nadbytek-zivin/"><button className="inv">&larr; Nedostatek a nadbytek živin v ČR</button></Link>
    <Link to="/ekologie-zivotni-prostredi/historie-lesu/"><button className="inv">Historie lesů na území ČR &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
